import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";
import { useUserState } from "src/context/userState";
import { ApplicationPaths } from "../../api/authorization/ApiAuthorizationConstants";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function authenticatedView(userName: string | null, logoutPath: string) {
    return (
        <Fragment>
            <NavItem>
                <div className="text-dark nav-link">Hello {userName}</div>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to="/logout">
                    Logout
                </NavLink>
            </NavItem>
        </Fragment>
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function anonymousView(loginPath: string) {
    return (
        <Fragment>
            <NavItem>
                <a className="nav-link text-dark" href="/authentication/login">
                    Login
                </a>
            </NavItem>
        </Fragment>
    );
}

export const LoginMenu = () => {
    const [state] = useUserState();

    const link = document.createElement("a");
    link.href = ApplicationPaths.Login;
    const urlPrefix = `${link.protocol}//${link.host}`;

    if (!state.user.isAuthenticated) {
        const loginPath = `${urlPrefix}${ApplicationPaths.Login}`;
        return anonymousView(loginPath);
    } else {
        const logoutPath = `${urlPrefix}${ApplicationPaths.LogOut}`;
        return authenticatedView(state.user.email, logoutPath);
    }
};
