import { Select } from "antd";
import React from "react";

interface FeeGroupSelectProps {
    title: string;
    value?: string;
    className?: string;
    items: string[];
    onChange: (value: string) => void;
}

export const FeeGroupSelect = ({
    title,
    value,
    items,
    className,
    onChange,
}: FeeGroupSelectProps) => {
    const options = items.map((item) => {
        return {
            value: item,
        };
    });

    return (
        <Select
            options={options}
            placeholder={`Select ${title}`}
            filterOption={(inputValue, option) =>
                option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
            allowClear={true}
            className={className}
            onSelect={onChange}
            onChange={onChange}
            value={value}
        />
    );
};
