import { AxiosRequestConfig } from "axios";
import { Logger } from "loglevel";
import { formatRequestMethod } from "./helpers";

export const createRequestLogger = (log: Logger) => (config: AxiosRequestConfig) => {
    if (log.getLevel() > log.levels.DEBUG) {
        return config;
    }

    const { data, method, params, url } = config;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const args: any[] = ["Calling", formatRequestMethod(method), url];

    if (params) {
        args.push("with params", params);
    }

    if (data) {
        args.push("with data", data);
    }

    log.debug(...args);
    log.trace("Request config:", config);

    return config;
};
