import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div>
                <h2>Storable Payments Administration</h2>
                <br />
                <h4>
                    <Link to="/custompricing">Custom Pricing</Link>
                </h4>
            </div>
        );
    }
}
