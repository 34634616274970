import { LogLevelDesc } from "loglevel";

/**
 * Defines the logger categories available in the app, in addition to the default logger.
 *
 * You can access loggers by importing log from loglevel and using log.getLogger(Loggers.name)
 */
export enum Loggers {
    // Records the requests and responses of ajax calls
    Ajax = "ajax",
}
export default Loggers;

type DefaultLogLevels = {
    readonly default: {
        readonly dev: LogLevelDesc;
        readonly prod: LogLevelDesc;
    };
} & {
    readonly [key: string]: {
        readonly dev?: LogLevelDesc;
        readonly prod?: LogLevelDesc;
    };
};

/**
 * Define the default levels for the various loggers.  Any logger not included here
 * will use the same levels as the default logger.
 */
export const defaultLogLevels: DefaultLogLevels = {
    default: {
        dev: "info",
        prod: "warn",
    },

    [Loggers.Ajax]: {
        dev: "warn",
        prod: "error",
    },
};
