import { useState } from "react";
import { ArkenstoneApi } from "../../api/arkenstone";
import { useAppSettings } from "../../context/appSettings";
import { useUserState } from "../../context/userState";
import { IFacilityPricing } from "../../models/facilityPricing";

export const useSaveFeeGroups = () => {
    const [{ arkenstoneUrl, arkenstoneApiKey }] = useAppSettings();
    const [{ user }] = useUserState();
    const [saving, setSaving] = useState(false);

    const saveFacilityPricing = async (feeGroup: IFacilityPricing) => {
        try {
            setSaving(true);
            const api = new ArkenstoneApi(arkenstoneUrl, arkenstoneApiKey, user.email);
            let pricingGroups: string[] = [
                feeGroup.authorizationFee,
                feeGroup.monthlyFee,
                feeGroup.discountRate || feeGroup.flatRate,
            ];
            //annual fees are optional
            if (feeGroup.annualFee) {
                pricingGroups = pricingGroups.concat(feeGroup.annualFee);
            }
            //Pci annual fees are optional
            if (feeGroup.pciAnnualFee) {
                pricingGroups = pricingGroups.concat(feeGroup.pciAnnualFee);
            }
            await api.updateFeeGroup(feeGroup.code, pricingGroups);
            setSaving(false);
        } catch (error) {
            setSaving(false);
            throw error;
        }
    };

    return [saveFacilityPricing, saving] as const;
};
