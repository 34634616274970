import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { addLogging } from "../../utilities/axios";
import { ISmaugApi } from "./interface";
import { IAppSettings } from "./models/appSettings";
import { IFeeGroup } from "./models/feeGroup";
import { IUser } from "./models/user";
//import authService from "../authorization/AuthorizeService";

export const setRequestCredentials = () => async (
    request: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
    return request;
};

export const shouldPerformRefresh = (response: AxiosResponse): boolean =>
    response.status === 401 && response.headers["token-expired"] !== undefined;

export class SmaugApi implements ISmaugApi {
    private readonly client: AxiosInstance;

    public constructor(url: string) {
        this.client = axios.create({
            baseURL: url,
            headers: {
                Accept: "application/json",
            },
        });
        this.client.interceptors.request.use(setRequestCredentials());
        this.client.interceptors.response.use(undefined, this.onRequestFailure);
        addLogging(this.client);
    }

    onRequestFailure(err: any) {
        // if (err?.response?.status == 401){
        //     alert('401');
        // }
        // else {

        Promise.reject(err);
        //        }
    }

    public async getFeeGroups() {
        const url = `pricing/feegroups`;
        const response = await this.client.get<IFeeGroup[]>(url, { withCredentials: true });
        return response.data;
    }

    public async login() {
        const url = `authentication/login`;
        const response = await this.client.get<IUser>(url);
        return response;
    }

    public async logout() {
        const url = `authentication/logout`;
        const response = await this.client.get<any>(url);
        return response;
    }

    public async getUser() {
        const url = "authentication/getUser";
        const response = await this.client.get<IUser>(url);
        return response;
    }

    public async getReactSettings() {
        const url = "authentication/getReactSettings";
        const response = await this.client.get<IAppSettings>(url);
        return response;
    }
}
