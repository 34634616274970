import { Alert, Button, Col, Form, Input, notification, Result, Row, Spin } from "antd";
import React, { useState } from "react";
import { IFacilityPricing } from "../../models/facilityPricing";
import "./CustomPricingForm.css";
import { FeeGroupSelect } from "./FeeGroupSelect";
import { useGetFeeGroups } from "./PopulateFeeGroups";
import { useSaveFeeGroups } from "./SaveFeeGroups";

export const CustomPricingForm = () => {
    const [form] = Form.useForm();
    const [code, setCode] = useState("");
    const [discountRate, setDiscountRate] = useState<string | undefined>();
    const [flatRate, setFlatRate] = useState<string | undefined>();
    const [monthlyFee, setMonthlyFee] = useState<string | undefined>();
    const [authorizationFee, setAuthorizationFee] = useState<string | undefined>();
    const [annualFee, setAnnualFee] = useState<string | undefined>();
    const [pciAnnualFee, setPciAnnualFee] = useState<string | undefined>();
    const [dropdowns, loading, loadFeeGroupError] = useGetFeeGroups();
    const [saveFacilityPricing, saving] = useSaveFeeGroups();

    // form event handlers
    const handleOnCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // uppercase and remove spaces as the user types or pastes
        setCode(event.currentTarget.value.toUpperCase().replace(/ /g, ""));
    };
    const handleDiscountRateChange = (value: string) => {
        setDiscountRate(value);
        setFlatRate("");
    };
    const handleFlatRateChange = (value: string) => {
        setFlatRate(value);
        setDiscountRate("");
    };
    const handleReset = () => resetForm();
    const handleSubmit = async () => {
        // manual validations
        if (code.indexOf("-") < 0 || code.length < 3) {
            notification["error"](codeValidationError);
            return;
        }
        const isRateSelected = discountRate || flatRate;
        if (!isRateSelected) {
            notification["error"](requiredRateError);
            return;
        }

        if (!monthlyFee || !authorizationFee) {
            notification["error"](requiredFeesError);
            return;
        }

        const facilityPricing: IFacilityPricing = {
            code,
            discountRate: discountRate || "",
            flatRate: flatRate || "",
            monthlyFee: monthlyFee || "",
            authorizationFee: authorizationFee || "",
            annualFee: annualFee || "",
            pciAnnualFee: pciAnnualFee || "",
        };

        // save data
        try {
            await saveFacilityPricing(facilityPricing);
            resetForm();
            notification["success"](saveSuccess);
        } catch (error) {
            notification["error"](saveError);
        }
    };

    const resetForm = () => {
        setCode("");
        setDiscountRate(undefined);
        setFlatRate(undefined);
        setMonthlyFee(undefined);
        setAuthorizationFee(undefined);
        setAnnualFee(undefined);
        setPciAnnualFee(undefined);
    };

    // notification definitions
    const notificationDuration = 15;
    const codeValidationError = {
        message: "Validation Error",
        description:
            "Code must be in the format CORP-LOCATION. Please use a dash between CorpCode and LocationCode.",
        duration: notificationDuration,
    };
    const requiredRateError = {
        message: "Validation Error",
        description: "Please enter a Discount Rate OR Flat Rate",
        duration: notificationDuration,
    };
    const requiredFeesError = {
        message: "Validation Error",
        description: "Please enter a Monthly Fee and a Authorization Fee.",
        duration: notificationDuration,
    };
    const saveError = {
        message: "Save Error",
        description: "Error saving fee group.",
        duration: notificationDuration,
    };
    const saveSuccess = {
        message: "Save Successful",
        description: `${code} Fee Groups have been saved.`,
        duration: notificationDuration,
    };

    // render error instead of form when dropdowns cannot be loaded
    if (loadFeeGroupError) {
        // eslint-disable-next-line no-console
        console.log(loadFeeGroupError);
        return (
            <Result className="error-message" status="warning" title="Error loading fee groups." />
        );
    }

    const formItemStyle = { labelCol: { span: 3 } };

    return (
        <div>
            <Spin spinning={loading || saving}>
                <h3>Custom Pricing</h3>
                <br />
                <Form form={form} layout="horizontal">
                    <Form.Item>
                        <h4>Enter CORP-LOCATION</h4>
                    </Form.Item>
                    <Form.Item>
                        <Alert
                            type="warning"
                            message="Warning"
                            description="You will NOT be notified if the submitted Corp and Location codes are incorrect. Please triple check the values before submitting."
                            showIcon
                        ></Alert>
                    </Form.Item>
                    <Form.Item label="Code*" {...formItemStyle}>
                        <Input
                            className="code"
                            placeholder="CORP-LOCATION"
                            onChange={handleOnCodeChange}
                            value={code}
                        />
                    </Form.Item>
                    <Form.Item>
                        <h4>Select a Rate*</h4>
                    </Form.Item>
                    <Row>
                        <Col span={1}></Col>
                        <Col span={11}>
                            <Form.Item label="Discount Rate">
                                <FeeGroupSelect
                                    title="Discount Rate"
                                    className="discount-rate"
                                    items={dropdowns.discount}
                                    value={discountRate}
                                    onChange={handleDiscountRateChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <h3 style={{ textAlign: "center" }}>OR</h3>
                        </Col>
                        <Col span={10}>
                            <Form.Item label="Flat Rate">
                                <FeeGroupSelect
                                    title="Flat Rate"
                                    className="flat-rate"
                                    items={dropdowns.flat}
                                    value={flatRate}
                                    onChange={handleFlatRateChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <h4>Select Fees</h4>
                    </Form.Item>
                    <Form.Item label="Monthly Fee*" {...formItemStyle}>
                        <FeeGroupSelect
                            title="Monthly Fee"
                            items={dropdowns.monthly}
                            value={monthlyFee}
                            onChange={setMonthlyFee}
                        />
                    </Form.Item>
                    <Form.Item label="Authorization Fee*" {...formItemStyle}>
                        <FeeGroupSelect
                            title="Authorization Fee"
                            items={dropdowns.auth}
                            value={authorizationFee}
                            onChange={setAuthorizationFee}
                        />
                    </Form.Item>
                    <Form.Item label="Annual Fee" {...formItemStyle}>
                        <FeeGroupSelect
                            title="Annual Fee"
                            items={dropdowns.annual}
                            value={annualFee}
                            onChange={setAnnualFee}
                        />
                    </Form.Item>
                    <Form.Item label="PCI Annual Fee" {...formItemStyle}>
                        <FeeGroupSelect
                            title="PCI Annual Fee"
                            items={dropdowns.pciAnnual}
                            value={pciAnnualFee}
                            onChange={setPciAnnualFee}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={handleReset} className="reset">
                            Reset
                        </Button>
                        <Button onClick={handleSubmit} type="primary">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};
