import axios, { AxiosInstance } from "axios";
import { IArkenstoneApi } from "./interface";
import { IFeeGroup } from "./models/feeGroup";

export class ArkenstoneApi implements IArkenstoneApi {
    private readonly client: AxiosInstance;

    public constructor(url: string, apiKey: string, userId: string) {
        this.client = axios.create({
            baseURL: url,
            headers: {
                Accept: "application/json",
                ApiKey: apiKey,
                UserId: userId,
            },
        });
    }

    public async getFeeGroups() {
        const url = `/api/v1/feeGroups`;
        const response = await this.client.get<{ groups: IFeeGroup[] }>(url);
        return response.data.groups;
    }

    public async updateFeeGroup(code: string, feeGroups: string[]) {
        const url = `/api/v1/feeGroups/${code}`;
        const response = await this.client.put<IFeeGroup>(url, { feeGroupNames: feeGroups });
        return response.data;
    }
}
