import React from "react";
import { Route } from "react-router";
import { AuthorizeRoute } from "./components/api-authorization/AuthorizeRoute";
import { LoginCallback } from "./components/api-authorization/LoginCallback";
import { Logout } from "./components/api-authorization/Logout";
import { Home } from "./components/Home";
import { Layout } from "./components/Layout";
import { CustomPricingForm } from "./components/Pricing";
import { AppSettingsProvider } from "./context/appSettings";
import { UserStateProvider } from "./context/userState";
import "./custom.css";

const App = () => {
    return (
        <UserStateProvider>
            <Layout>
                <Route exact path="/" component={Home} />
                <Route path="/logout" component={Logout} />
                <Route path="/loginCallback" component={LoginCallback} />
                <AuthorizeRoute path="/custompricing">
                    <AppSettingsProvider>
                        <CustomPricingForm />
                    </AppSettingsProvider>
                </AuthorizeRoute>
            </Layout>
        </UserStateProvider>
    );
};

export default App;
