import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { SmaugApi } from "src/api/smaug/api";
import { useUserState } from "src/context/userState";

export const Logout = () => {
    const [, dispatch] = useUserState();
    const [state, setState] = useState({ message: "Logging out...", redirect: false });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    new SmaugApi("").logout().then((response) => {
        dispatch({ type: "LOGOUT", payload: { user: { email: "", isAuthenticated: false } } });
        setState({ message: "Logged out.  Redirecting...", redirect: true });
    });

    if (state.redirect) {
        return <Redirect to="/" />;
    } else {
        return <div>{state.message}</div>;
    }
};
