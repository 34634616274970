import { Component } from "react";
import React from "react";

interface IProps {
    to: string;
}

interface IState {
    to: string;
}
export class RedirectExternal extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { ...props };
    }
    componentDidMount() {
        window.location.assign(this.state.to);
    }

    render() {
        return <section>Redirecting...</section>;
    }
}
