import { Spin } from "antd";
import Cookies from "js-cookie";
import React, { useContext } from "react";
import { useGetAppSettings } from "./getAppSettings";

interface IAppSettings {
    arkenstoneUrl: string;
    arkenstoneApiKey: string;
}
const initialAppSettings: IAppSettings = {
    arkenstoneUrl: "", // comes from user secrets (local) or environment variable and is passed via AJAX
    arkenstoneApiKey: Cookies.get("ArkenstoneApiKey") || "", // comes from user secrets (local) or environment variable but is passed via a cookie at login
};

const validateSettings = () => {
    if (!initialAppSettings.arkenstoneApiKey) {
        // eslint-disable-next-line no-console
        console.error(
            "Error: Arkenstone API Key was not passed via a cookie at login. If you are running locally then add Authentication.ArkenstoneApiKey to your user secrets."
        );
    }
};

export const AppSettingsProvider: React.FunctionComponent = ({ children }) => {
    const [settings, loading] = useGetAppSettings(); // loads the arkenstoneUrl via AJAX
    const combinedSettings = {
        ...initialAppSettings,
        arkenstoneUrl: settings ? settings.arkenstoneUrl : "",
    };

    validateSettings();

    // display a loader until the arkenstone URL is loaded
    if (loading) {
        return (
            <div style={{ textAlign: "center" }}>
                <Spin />
            </div>
        );
    }

    return (
        <AppSettingsContext.Provider value={combinedSettings}>
            {children}
        </AppSettingsContext.Provider>
    );
};

export const AppSettingsContext = React.createContext(initialAppSettings);
export const useAppSettings = () => [useContext(AppSettingsContext)] as const;
