import { useEffect, useState } from "react";
import { ArkenstoneApi } from "../../api/arkenstone";
import { useAppSettings } from "../../context/appSettings";
import { useUserState } from "../../context/userState";
import { FeeType } from "../../models/enums";
import { IFeeGroup } from "../../models/feeGroup";

export interface FeeGroupDropdowns {
    discount: string[];
    flat: string[];
    monthly: string[];
    auth: string[];
    annual: string[];
    pciAnnual: string[];
}

export const useGetFeeGroups = () => {
    const [{ arkenstoneUrl, arkenstoneApiKey }] = useAppSettings();
    const [{ user }] = useUserState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | undefined>(undefined);
    const [dropdowns, setDropdowns] = useState<FeeGroupDropdowns>({
        discount: [],
        flat: [],
        monthly: [],
        auth: [],
        annual: [],
        pciAnnual: [],
    });
    useEffect(() => {
        const fetchData = async () => {
            try {
                const api = new ArkenstoneApi(arkenstoneUrl, arkenstoneApiKey, user.email);
                const feeGroups = await api.getFeeGroups();

                if (!feeGroups || feeGroups.length === 0) {
                    throw new Error("Error loading fee groups. No Fee Groups were found.");
                }
                const annualArray = filterByFeeType(feeGroups, FeeType.Annual);
                const annualPCIArray = filterByFeeType(feeGroups, FeeType.AnnualPCI);
                setDropdowns({
                    discount: filterByFeeType(feeGroups, FeeType.Discount),
                    flat: filterByFeeType(feeGroups, FeeType.FlatRate),
                    monthly: filterByFeeType(feeGroups, FeeType.Monthly),
                    auth: filterByFeeType(feeGroups, FeeType.Auth),
                    annual: annualArray,
                    pciAnnual: annualPCIArray,
                });
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };
        fetchData();
    }, [arkenstoneUrl, arkenstoneApiKey, user]);

    return [dropdowns, loading, error] as const;
};

export const filterByFeeType = (feeGroups: IFeeGroup[], feeType: FeeType): string[] => {
    return feeGroups
        .reduce((array, curr) => {
            if (curr.feeType === feeType) {
                return [...array, curr.name];
            }
            return array;
        }, [] as string[])
        .sort();
};
