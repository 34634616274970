import { AxiosInstance } from "axios";
import log from "loglevel";
import Loggers from "../../loggers";
import { createRequestErrorLogger } from "./createRequestErrorLogger";
import { createRequestLogger } from "./createRequestLogger";
import { createResponseErrorLogger } from "./createResponseErrorLogger";
import { createResponseLogger } from "./createResponseLogger";

const ajaxLog = log.getLogger(Loggers.Ajax);

export const addLogging = (instance: AxiosInstance) => {
    const requestInterceptorId = instance.interceptors.request.use(
        createRequestLogger(ajaxLog),
        createRequestErrorLogger(ajaxLog)
    );

    const responseInterceptorId = instance.interceptors.response.use(
        createResponseLogger(ajaxLog),
        createResponseErrorLogger(ajaxLog)
    );

    return [requestInterceptorId, responseInterceptorId];
};
