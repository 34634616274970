import { AxiosResponse } from "axios";
import { Logger } from "loglevel";
import { formatRequestMethod } from "./helpers";

export const createResponseLogger = (log: Logger) => (response: AxiosResponse) => {
    if (log.getLevel() > log.levels.DEBUG) {
        return response;
    }

    const {
        config: { method, url },
        data,
    } = response;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const args: any[] = ["Call to", formatRequestMethod(method), url, "complete"];

    if (data) {
        args.push("with data", data);
    }

    log.debug(...args);
    log.trace("Response:", response);

    return response;
};
