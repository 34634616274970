import { useEffect, useState } from "react";
import { SmaugApi } from "../../api/smaug";
import { IAppSettings } from "../../models/appSettings";

export const useGetAppSettings = () => {
    const [loading, setLoading] = useState(true);
    const [settings, setSettings] = useState<IAppSettings | undefined>();

    useEffect(() => {
        getSettings();
    }, []);

    const getSettings = async () => {
        try {
            setLoading(true);

            const api = new SmaugApi("");
            const currentSettings = await api.getReactSettings();

            setSettings(currentSettings.data);

            setLoading(false);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(
                "Error: Arkenstone URL is not available. If you are running locally then please add ReactSettings.ArkenstoneUrl to your user secrets."
            );

            setLoading(false);
        }
    };

    return [settings, loading] as const;
};
