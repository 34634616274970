import React from "react";
import { Route } from "react-router-dom";
import { useUserState } from "src/context/userState";
import {
    ApplicationPaths,
    QueryParameterNames,
} from "../../api/authorization/ApiAuthorizationConstants";
import { RedirectExternal } from "../RedirectExternal";

interface IProps {
    path: string;
}
export const AuthorizeRoute: React.FunctionComponent<IProps> = ({ children, path, ...rest }) => {
    const [state] = useUserState();

    const link = document.createElement("a");
    link.href = path;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(
        returnUrl
    )}`;

    return (
        <Route
            {...rest}
            path={path}
            render={() => {
                if (state.user.isAuthenticated) {
                    return <React.Fragment>{children}</React.Fragment>;
                } else {
                    return <RedirectExternal to={redirectUrl} />;
                }
            }}
        />
    );
};
