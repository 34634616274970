import React, { ReactNode } from "react";
import { IUser } from "src/api/smaug/models/user";

type Action = { type: "LOGIN" | "LOGOUT" | "UPDATE"; payload: { user: IUser } };
type UserState = { user: IUser };
type Dispatch = (action: Action) => void;
const userStateContext = React.createContext<UserState>({
    user: { isAuthenticated: false, email: "" },
});
// eslint-disable-next-line @typescript-eslint/no-empty-function
const dispatchStateContext = React.createContext<Dispatch>(() => {});

const userReducer = (state: UserState, action: Action) => {
    switch (action.type) {
        case "LOGIN":
            localStorage.setItem("user", JSON.stringify(action.payload.user));
            return {
                ...state,
                user: action.payload.user,
            };
        case "LOGOUT":
            localStorage.clear();
            return {
                ...state,
                user: { isAuthenticated: false, email: "" },
            };
        case "UPDATE":
            if (!action.payload.user.isAuthenticated) {
                localStorage.clear();
            } else {
                localStorage.setItem("user", JSON.stringify(action.payload.user));
            }
            return {
                ...state,
                user: action.payload.user,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

interface IProps {
    children: ReactNode;
}

export const UserStateProvider = ({ children }: IProps) => {
    const [state, dispatch] = React.useReducer(userReducer, {
        user: { isAuthenticated: false, email: "" },
    });

    return (
        <userStateContext.Provider value={state}>
            <dispatchStateContext.Provider value={dispatch}>
                {children}
            </dispatchStateContext.Provider>
        </userStateContext.Provider>
    );
};

export const useUserState = () =>
    [React.useContext(userStateContext), React.useContext(dispatchStateContext)] as const;
