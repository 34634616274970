import React from "react";
import { Redirect } from "react-router-dom";
import { SmaugApi } from "src/api/smaug/api";
import { useUserState } from "src/context/userState";

export const LoginCallback = () => {
    const [, dispatch] = useUserState();

    new SmaugApi("")
        .getUser()
        .then((response) => dispatch({ type: "LOGIN", payload: { user: response.data } }));

    return <Redirect to="/" />;
};
