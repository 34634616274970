import { AxiosRequestConfig } from "axios";
import { Logger } from "loglevel";
import { formatRequestMethod } from "./helpers";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createResponseErrorLogger = (log: Logger) => (e: any) => {
    if (e.config) {

        const config = e.config as AxiosRequestConfig;
        const method = config.method;
        const url = config.url;
        const response = e.response;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const args: any[] = ["Call to", formatRequestMethod(method), url, "failed"];

        if (response) {
            const { data, status } = response;
            args.push("with status code", status);

            if (data) {
                args.push("and response data", data);
            }
        }

        log.error(...args);
        log.debug("Error object:", e);
    } else {
        log.error("Unexpected error during an axios request:", e);
    }

    return Promise.reject(e);
};
